body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;

}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html {
  background: #191b1f;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Flex styles -------------------------------------------------*/
header {
  display: flex;
  align-items: center;
}

@media (min-width: 700px) {
 .leading {
    display: flex;
    align-items: center;
  }
}


/* Grid styles -------------------------------------------------*/
.cards {
  /*display: grid;*/
  /*original grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));*/
  /*grid-template-columns: repeat(auto-fill, minmax(217px, 1fr));
  grid-gap: 30px;*/
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(189px, 1fr));
  grid-gap: 13px;
}


/* Generic styles ----------------------------------------------*/
body {
  font-family: 'Poppins', sans-serif;
}

header {
  padding: 10px;
  font-size: 2em;
  color: white;
  background-color: #333;
}

section {
  background: #191b1f;
}

.logo {
  height: 50px;
  margin-right: 20px;
}

.leading {
  height: 240px;
  margin-bottom: 30px;
  padding: 30px;
  color: white;
  background: url('https://cdn.glitch.com/a5121e34-96b3-4a70-8227-040c51e64fae%2Fcat.jpg?1509635989509') center #333 no-repeat;
  background-size: cover;
  color: white;
  text-shadow: 0px 0px 5px #000;
}

.leading-bigtext {
  margin-right: 60px;
  font-weight: bold;
  font-size: 24vw;
}

@media (min-width: 700px) {
  .leading-bigtext {
    font-size: 140px;
  }
}

.leading-text {
  max-width: 900px;
  font-size: 1.2em;
  line-height: 1.4em;
}

.cards {
  /*max-width: 960px;
  margin: 0 auto 30px;*/
  max-width: 1200;
  /*margin: 12px auto auto 100px;*/
  /*margin: 118px auto auto 100px;*/
}

article {
  position: relative;
  background: #343535;
  margin-bottom: 1rem;
}

.article-img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.article-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.card-header {
  padding-left: 6px;
  padding-top: 10px;
  font-size: 10px;
}

.card-middle {
  padding-top: 20px;
  padding-left: 10px;
  font-size: -webkit-xxx-large;
}
.card-end {
  /*color:#dd5257;*/
  padding-top: 20px;
  padding-left: 10px;
  font-size: 35px;
  padding-bottom: 35px;
}

.card-end-w {
  color:#fff;
  padding-top: 20px;
  padding-left: 10px;
  font-size: 23px;
  font-weight: lighter;
  padding-bottom: 35px;
}

.header-card-text {
  color: #fff;
  font-size: medium;
  font-size: initial;
}

.span-position-card {
  color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
  left: 0;
  padding: 7px;
}

article > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.article-icon-loading {
  text-align: center;
  background-color: transparent;
}

.icon-loading {
  color:#f6c12c;
  line-height: 4;
}
.center-content{
  /*display: -webkit-flex;*/
  /*display: flex;*/
  text-align: center;
  flex-wrap: wrap;
  align-content: center;
}

main {
  padding: 1rem;
}

.hidden {
  display: none;
}
